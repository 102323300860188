import api from "!../../../../.yarn/__virtual__/style-loader-virtual-ccd4cfe1ee/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-21425246a5.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../.yarn/__virtual__/css-loader-virtual-dbba007605/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/cjs.js!../../../../.yarn/__virtual__/less-loader-virtual-8169d4fac7/0/cache/less-loader-npm-8.1.1-3efd61be2e-d1771d3e77.zip/node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!./index.less";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};